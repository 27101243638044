import React from 'react'
import {Link} from 'gatsby'
import { navigate } from "gatsby"


export default function Navbar() {
  return (
      <nav>
        <div className="container">
          <div className="logo" role = "link" onClick={()=>{navigate("/")}} onKeyDown={()=>{navigate("/")}}></div>
          <li className="navlinks">
          <Link to="/about" className = "navlink1">About</Link>
          <Link to="/services" className = "navDropDownAnchor">Services</Link>
          <Link to="/projects">Projects</Link>
          <Link to="/demo">Demo</Link>
          <Link to="/blog">Blog</Link>
          </li>
        </div>
      </nav>
  )
}

// const logo = document.getElementsByClassName("logo");
// logo.addEventListener=("click", () => {navigate("/blog")});
// console.log(logo);
// function f1() {logo.style.backgroundColor="black"};
// f1();

// logo.addEventListener=('click', function(){navigate("/blog")});