import React from 'react'
import Navbar from "../components/navbar.js"
import "../styles/global.css"


export default function Layout( {children} ) {
  return (
    <div>
      <Navbar/>
        {children}
      <footer> Legit WebDev Copyright 2022 </footer>
    </div>
  )
}